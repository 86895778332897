import React from "react"
import "./Login.css"

import {LoginFlow} from "@ory/client"

function Login({loginFlow}: { loginFlow: LoginFlow }) {
  return <div className="Login">
    <div className="Login-title">VivaChat</div>
    <form action={`${loginFlow.ui.action}&return_to=${encodeURIComponent(window.location.origin)}`}
          method={loginFlow.ui.method}>
      <button className="Login-google-btn" type="submit" name="provider" value="google">Sign In</button>
      <input type="hidden" name="upstream_parameters.hd" value="vivacitylabs.com"/>
    </form>
    <div className="Login-subtitle">Your conversations are logged by VivaCity to improve future performance</div>
  </div>
}

export default Login