import React, {useState} from 'react';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator
} from "@chatscope/chat-ui-kit-react";
import {LogoutFlow} from "@ory/client";

import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import './Chat.css';

interface GPTMessage {
  role: "system" | "user" | "assistant" | "function";
  content?: string;
}

const chatId = crypto.randomUUID();

const baseMessage = {
  type: "MESSAGE",
  conversation_id: chatId,
}

const headers = new Headers();
headers.append("Content-Type", "application/json");

const baseRequestOptions: RequestInit = {
  method: 'POST',
  credentials: "include",
  mode: "cors",
  headers,
}

const apiUrl = window.location.hostname === "localhost" ? "http://localhost:80/client" : "https://tender-chat.viva-chat.dev.vivacitylabs.com/client"

const generateRequestMessage = (message: string): RequestInit => {
  const body = {
    ...baseMessage,
    message: {
      type: "REQUEST",
      chat_completion_message: {
        role: "user",
        content: message
      }
    }
  }

  return {
    ...baseRequestOptions,
    body: JSON.stringify(body)
  }
}

function Chat({logoutFlow}: { logoutFlow: LogoutFlow }) {
  const [messages, setMessages] = useState<GPTMessage[]>([])
  const [isSending, setIsSending] = useState(false)

  const handleLogout = async () => {
    await fetch(logoutFlow?.logout_url!, {
      mode: "cors",
      credentials: "include",
      redirect: "manual"
    })
    window.location.replace(window.origin)
  }

  const onSend = async (innerHtml: string, textContent: string, innerText: string, nodes: NodeList) => {
    console.log(chatId)
    setIsSending(true)
    const newMessages: GPTMessage[] = [...messages, {role: "user", content: textContent}]
    setMessages(newMessages)

    const request = generateRequestMessage(textContent)
    const response = await fetch(apiUrl, request)
    if (response.status !== 200) {
      console.error(`Got non-200 response: ${response.status}`);
      return;
    }
    if (!response.body) {
      console.error('Unable to get response body');
      return;
    }
    console.log(response)

    const responseMessage: GPTMessage = {
      role: "assistant",
      content: ""
    }
    setMessages([...newMessages, responseMessage])

    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');

    let result = '';
    while (true) {
      const {done, value} = await reader.read();
      if (done) break;

      result += decoder.decode(value, {stream: true});
      responseMessage.content = result
      setMessages([...newMessages, responseMessage])
    }
    result += decoder.decode();
    responseMessage.content = result
    setMessages([...newMessages, responseMessage])
    setIsSending(false)
  }

  return (
    <div className="Chat">
      <div className="Chat-header">
        <p className="Chat-chat-id">Chat ID: {chatId}</p>
        <button className="Chat-logout-btn" onClick={handleLogout}>Logout</button>
      </div>
      <MainContainer className="Chat-container">
        <ChatContainer>
          <MessageList typingIndicator={isSending ? <TypingIndicator content="Vivacity is typing"/> : null}>
            {messages.filter(m => m.role == "user" || m.role == "assistant").map((m, i) =>
              <Message
                key={`${i}`}
                model={{
                  message: m.content,
                  sentTime: "just now",
                  sender: m.role,
                  direction: m.role === "user" ? "outgoing" : "incoming",
                  position: "normal"
                }}
              />)}
          </MessageList>
          <MessageInput placeholder="Type message here" attachDisabled={true} disabled={isSending} onSend={onSend}/>
        </ChatContainer>
      </MainContainer>
    </div>

  )
}

export default Chat;